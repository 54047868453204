import React from "react";
import Layout from "@/components/Layout";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Service from "@/components/Services";
import { useLocation } from "@reach/router";
import SEO from "@/components/seo";

const Services = () => {
  const canonical = `${useLocation().host}`;
  return (
    <Layout>
      <SEO title="Services" canonical={canonical} />
      <Header />
      <Service />
      <Footer />
    </Layout>
  );
};

export default Services;
