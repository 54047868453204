import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";

const MobileHeader = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    const body = document.getElementsByTagName("body").item(0);
    const CanvasToggle = document.querySelector(".aside-toggler");
    const triggerToggle =
      pathname !== "/"
        ? document.querySelector(".tr3")
        : document.querySelector(".tr2");
    const aside = document.querySelector(".main-aside");
    const overlay = document.querySelector(".aside-overlay");
    const closeBtn = document.querySelector(".close-btn");

    if (body && CanvasToggle && aside && overlay && closeBtn && triggerToggle) {
      CanvasToggle.addEventListener("click", (e) => {
        e.preventDefault();
        body.classList.add("aside-open");
        aside.classList.add("open");
      });
      triggerToggle.addEventListener("click", (e) => {
        e.preventDefault();
        body.classList.add("aside-open");
        aside.classList.add("open");
      });

      overlay.addEventListener("click", (e) => {
        e.preventDefault();
        body.classList.remove("aside-open");
        aside.classList.remove("open");
      });

      closeBtn.addEventListener("click", (e) => {
        e.preventDefault();
        body.classList.remove("aside-open");
        aside.classList.remove("open");
      });
    }

    return () => {
      window.removeEventListener("click", () => {});
    };
  }, []);
  return (
    <>
      <aside className="main-aside">
        <div className="aside-title">
          <div className="aside-controls aside-trigger">
            <h4>Menu</h4>
            <div className="close-btn close-dark">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>

        <div className="aside-scroll">
          <ul>
            <li className="menu-section-title">
              <Link to="/">Home</Link>
            </li>
            <li className="menu-section-title">
              <Link to="/about">About</Link>
            </li>
            <li className="menu-section-title">
              <Link to="/services">Services</Link>
            </li>
            <li className="menu-section-title">
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
      </aside>
      <div className="aside-overlay aside-trigger"></div>
    </>
  );
};

export default MobileHeader;
