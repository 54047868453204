import React from "react";

const GetStarted = () => {
  return (
    <div className="section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-lg-30 acr-dots-wrapper acr-single-img-wrapper">
            <img src="images/listings-list/4.jpg" alt="img" />
            <div className="acr-dots"></div>
          </div>
          <div className="col-lg-6">
            <div className="section-title-wrap mr-lg-30">
              <h2 className="title">Our Services</h2>
              <p className="subtitle">
                Estero Bicycle Cafe provides quality services by professionally
                trained mechanics with the experience and tools to care for your
                bicycle needs in the shortest possible time. We can help you
                ride with more confidence.{" "}
              </p>
              <p>We guarantee to the cycling community:</p>
              <ul className="acr-list">
                <li>Efficiency</li>
                <li>Quality</li>
                <li>Safety</li>
                <li>Affordable prices</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
