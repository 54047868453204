import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import Image from "@/components/Image";
import "@/components/Header/style.css";
import MobileHeader from "@/components/Header/MobileHeader";

const Header = () => {
  const { pathname } = useLocation();
  const [stickyClass, setStickyClass] = React.useState("");
  const [hidden, setHidden] = React.useState(pathname !== "/" ? "d-none" : "");

  React.useEffect(() => {
    window.addEventListener("scroll", (event) => {
      const doc = event.target as Document;
      const scroll = doc.scrollingElement?.scrollTop
        ? doc.scrollingElement?.scrollTop
        : 0;

      if (scroll > 120 && stickyClass !== "sticky" && pathname !== "/") {
        setHidden("");
        setStickyClass("sticky");
      } else {
        setStickyClass("");
      }
      if (scroll < 120 && pathname !== "/") {
        setHidden("d-none");
      }
    });
  }, []);
  return (
    <>
      <header
        className={`main-header header-3 can-sticky ${stickyClass} ${hidden}`}
      >
        <nav className="navbar">
          <div className="container">
            <a className="navbar-brand" href="/">
              {" "}
              <Image name="logo-transparent.png" classes="logo-img" />
            </a>
            <ul className="navbar-nav">
              <li className="menu-item menu-item-has-children">
                <Link to="/">Home</Link>
              </li>
              <li className="menu-item menu-item-has-children">
                <Link to="/about">About Us</Link>
              </li>
              <li className="menu-item menu-item-has-children">
                <Link to="/services">Services</Link>
              </li>
              <li className="menu-item menu-item-has-children">
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>

            <div className="header-controls">
              <div id="tog" className="aside-toggler aside-trigger tr2">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <MobileHeader />
    </>
  );
};

export default Header;
