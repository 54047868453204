import { Link } from "gatsby";
import React from "react";

const Info = () => {
  return (
    <div className="section pt-0 mt-5">
      <div className="container">
        <div className="cta cta-1">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <h3>Need More Information About Estero Bicycle Cafe?</h3>
            </div>
            <div className="offset-lg-1 col-lg-7">
              <p>
                We provide repair and maintenance services to the cycling
                community, the best solution to their bicycle needs. Bring your
                bike and talk with our mechanics staff about which service is
                the correct for you while also you can enjoy a good coffee.
              </p>
              <Link to="/about" className="btn-custom-2 light">
                Read more
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
