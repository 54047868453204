import React from "react";

const Services = () => {
  return (
    <div
      className="section light-bg infographics-2 bg-norepeat bg-bottom"
      //   style="background-image: url('assets/img/misc/bldg.png')"
      style={{ backgroundImage: "url('images/misc/bldg.png')" }}
    >
      <div className="container">
        <div className="section-title-wrap section-header">
          <h5 className="custom-primary">Services</h5>
          <h2 className="title">Available for all kind of bicycles</h2>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6" id="basic-tune-up">
            <div className="acr-infographic-item">
              <i className="flaticon-helmet"></i>
              <h5>Basic Tune-up</h5>
              <p>
                We will make sure everything is tightened and aligned correctly.
                If you want to have a great experience with your bike would be
                better to know that all parts are in good shape.
              </p>
              <a href="#">
                {" "}
                <span>Get Started</span>{" "}
                <i className="fas fa-chevron-right"></i>{" "}
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6" id="standard-tune-up">
            <div className="acr-infographic-item">
              <i className="flaticon-helmet"></i>
              <h5>Standard Tune-up</h5>
              <p>
                Regular tune-up is essential to keep the bike components
                functioning within their optimum range, prolonging their life,
                and it's also necessary for a safe ride.
              </p>
              <a href="#">
                {" "}
                <span>Get Started</span>{" "}
                <i className="fas fa-chevron-right"></i>{" "}
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6" id="overhaul">
            <div className="acr-infographic-item">
              <i className="flaticon-location"></i>
              <h5>Overhaul</h5>
              <p>
                Restoring heavily used bikes with worn systems will need lots of
                parts replaced, but every bicycle deserves it. if you ride
                regularly, a complete overhaul is in your future.
              </p>
              <a href="#">
                {" "}
                <span>Get Started</span>{" "}
                <i className="fas fa-chevron-right"></i>{" "}
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6" id="repair">
            <div className="acr-infographic-item">
              <i className="flaticon-bicycle"></i>
              <h5>Repair</h5>
              <p>
                Repair your bike problems and recover its functioning as soon as
                possible. We'll ensure that your bicycle running in top
                condition. Roll faster and smoother than before.
              </p>
              <a href="#">
                {" "}
                <span>Get Started</span>{" "}
                <i className="fas fa-chevron-right"></i>{" "}
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6" id="refit">
            <div className="acr-infographic-item">
              <i className="flaticon-bicycle-parking"></i>
              <h5>Refit</h5>
              <p>
                Make the right changes and you'll breathe new life into your old
                bike. Everything will be in its place again, better than before.
                Your ride experience will improve considerably.
              </p>
              <a href="#">
                {" "}
                <span>Get Started</span>{" "}
                <i className="fas fa-chevron-right"></i>{" "}
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6" id="consultation">
            <div className="acr-infographic-item">
              <i className="flaticon-location"></i>
              <h5>Consultation</h5>
              <p>
                We provide consulting services. Bring your bike and consult our
                team of experts and we will help you find the best solution. It
                will be a pleasure to assist you any time you need.
              </p>
              <a href="#">
                {" "}
                <span>Get Started</span>{" "}
                <i className="fas fa-chevron-right"></i>{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
