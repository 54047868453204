import React from "react";
import Header from "@/components/Header/Header";
import SubHeader from "@/components/About/SubHeader";
import GetStarted from "@/components/Services/GetStarted";
import Info from "@/components/Services/Info";
import Service from "@/components/Home/Services";
const Services = () => {
  return (
    <>
      <Header />
      <SubHeader title="Services" />
      <GetStarted/>
      <Service/>
      <Info/>
    </>
  );
};

export default Services;
